import React from 'react';
import { Typography, Grid } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const MiddleQuadrantComponent = ({ icon, text, subtext, image }) => {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <Grid container padding={3} justifyContent="center" alignItems="center" >
                    <Grid container xs={12} sm={8} md={8} xl={7} padding={2} style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}>
                        <Grid item md={6} xs={12}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"  // Ensures the Box takes up the full height of the Grid item
                                //    // Add padding for a box-like shape
                            >
                                {icon && <div style={{ color: '#3b3b3b', fontSize: '20px' }}>{icon}</div>}
                                {/* Display text */}
                                <Typography sx={{ fontFamily: 'Abel' }} padding={2} variant="h4" color="#3b3b3b">
                                    {text}
                                </Typography>
                                {/* Display subtext */}
                                <Typography sx={{ fontFamily: 'Abel' }} padding={2} variant="h6" color="#3b3b3b">
                                    {subtext}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Display image on the right */}
                        <Grid item md={6} xs={12} >
                            <Box display="flex" padding={2} justifyContent="center">
                                <Avatar
                                    src={image}
                                    sx={{
                                        width: '80%', // Specify the width for all screen sizes
                                        height: '80%', // Specify the height for all screen sizes
                                        borderRadius: '3%',
                                        // '@media (min-width: 960px)': { // Adjust the size for medium screens (md breakpoint)
                                        //     width: '50%', // 10% larger than the default size
                                        //     height: '50%', // 10% larger than the default size
                                        // }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    );
};

export default MiddleQuadrantComponent;
