import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';



let theme = createTheme();
theme = responsiveFontSizes(theme);


const Buttontheme = createTheme({
    palette: {
        secondary: {
            main: "#2074d4",
        },
    },
});



function Pricing() {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <Grid container spacing={5} padding={5} sx={{ bgcolor: '#fafbfd' }}>
                    <Grid item align="center" xs={12} md={6}>                    
                        <Card elevation={6} variant="outlined" sx={{ maxWidth: 400 }} style={{ backgroundColor: "#161736", borderRadius: 16 }}>
                            <CardContent>
                                <Box >
                                    <Box textAlign='center'>
                                        <Typography color="white" sx={{ fontFamily: 'Abel' }} variant="h3" mb={5} component="h2" gutterBottom={true}>
                                            Demo
                                        </Typography>
                                        <Typography color="white" sx={{ fontFamily: 'Abel' }} variant="h1" component="h2" gutterBottom={true}>
                                            $0
                                            <Typography sx={{ fontFamily: 'Abel' }} variant="h6" color="white" component="span">/mo</Typography>
                                        </Typography>
                                    </Box>
                                    <Typography style={{ fontSize: '20px', textAlign: 'center' }} sx={{ fontFamily: 'Abel' }} color="white" variant="subtitle1" component="p">Select Group</Typography>
                                    <Typography style={{ fontSize: '20px', textAlign: 'center' }} sx={{ fontFamily: 'Abel' }} color="white" variant="subtitle1" component="p">Transcibe Audio</Typography>
                                    <Typography style={{ fontSize: '20px', textAlign: 'center' }} sx={{ fontFamily: 'Abel' }} color="white" variant="subtitle1" component="p">AI Generated Notes</Typography>
                                </Box>
                                <Box textAlign='center' mt={5}>
                                    <ThemeProvider theme={Buttontheme}>
                                        <Button style={{ fontSize: '20px' }} sx={{ fontFamily: 'Abel' }} variant="contained" color="secondary" href='./signup' >Get Started</Button>
                                    </ThemeProvider>
                                </Box>
                            </CardContent>
                        </Card>

                    </Grid>

                    <Grid item
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center" xs={12} md={5}>
                        <Typography variant="h3" padding={2} sx={{ fontFamily: 'Abel' }} color="#3b3b3b">
                            Join our Demo Group
                        </Typography>
                        <Typography variant="h6" padding={2} sx={{ fontFamily: 'Abel' }} color="#3b3b3b">

                        Join our exclusive demo group to experience our AI software transforming audio into text SOAP notes. Revolutionize your documentation with efficiency and precision, making informed decision-making effortless. Elevate your practice – join our limited demo group now!                        </Typography>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>

    );
}

export default Pricing;
