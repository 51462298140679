// PatientInformation.js
import React, { useState, useEffect } from 'react';
import apis from '../../../../api';
import { Paper, Grid, Typography, Divider } from '@mui/material';

const PatientInformation = ({ selectedPatient, userID }) => {
  const [patientInfo, setPatientInfo] = useState(null);

  useEffect(() => {
    const fetchPatientInfo = async () => {
      try {
        userID = JSON.parse(userID)._id;
        const authToken = localStorage.getItem('jwtToken');

        const response = await apis.getPatientByPatientID(
          { userId: userID, patientId: selectedPatient._id },
          authToken
        );

        setPatientInfo(response.data);
        // console.log(response.data)
      } catch (error) {
        console.error('Error fetching patient information:', error);
      }
    };

    if (selectedPatient) {
      fetchPatientInfo();
    }
  }, [selectedPatient]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Check if patientInfo is null
  if (!patientInfo) {
    return <div>Loading patient information...</div>;
  }

  return (
    <Grid>
      <Paper elevation={3} style={{ height: '100%', padding: '16px' }}>
        <Typography variant="h6">Name: {patientInfo.firstname + ' ' + patientInfo.lastname}</Typography>
        <Typography>Age: {calculateAge(patientInfo.dob)}</Typography>
        <Typography>DOB: {formatDate(patientInfo.dob)}</Typography>
        <Typography>Gender: {patientInfo.gender}</Typography>
        {/* Add more patient information fields as needed */}
      </Paper>
    </Grid>
  );
};

export default PatientInformation;
