import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

function AnonymousRoute () {
    //check if we have a valid user in storage
    const user = JSON.parse(localStorage.getItem('user'));
    // return user ? <Navigate to="/" replace /> : <Outlet />;
    return user ? <Navigate to="/userconsole" replace /> : <Outlet />;

  }

export default AnonymousRoute;
