import axios from 'axios';

const baseURL = 'https://secure.getwava.com:443/' //direct to instnace
// const baseURL = 'http://localhost:5000/' //direct to instnace

const api = axios.create({
    baseURL
})

export const registerUser = payload => api.post(`api/users/register/`, payload)

export const loginUser = payload => api.post(`api/users/login/`, payload)

// may need to change to handle authToken
export const addPatient = (payload, authToken) => {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.post('api/patients/addPatient/', payload, { headers });
};

export const addPatientNote = (payload, authToken) => {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.post('api/patients/addPatientNote/', payload, { headers });
};

export const getPatientsByUserID = (userId, authToken) => {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.get(`/api/patients/getAllPatients?userId=${userId}`, { headers });
};

export const getPatientByPatientID = (payload, authToken) => {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.get(`/api/patients/getPatient?userId=${payload.userId}&patientId=${payload.patientId}`, { headers });
};

export const getPatientNotes = (payload, authToken) => {
    console.log(payload.userId)
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.get(`/api/patients/getAllPatientNotes?patientId=${payload.patientId}&userId=${payload.userId}`, { headers });
};

export const getPatientNote = (payload, authToken) => {
    console.log(payload.userId)
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return api.get(`/api/patients/getPatientNote?patientId=${payload.patientId}&userId=${payload.userId}&noteId=${payload.noteId}`, { headers });
};

const apis = {
    registerUser,
    loginUser,
    getPatientsByUserID,
    addPatient,
    addPatientNote,
    getPatientByPatientID,
    getPatientNotes,
    getPatientNote
}

export default apis