import React, { useState, useEffect } from 'react';
import apis from '../../../../api';
import { Paper, Button, Grid } from '@mui/material';
import { ReactMic } from 'react-mic';


const NewPatientNote = ({ onNoteSelect, selectedPatient }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);

    useEffect(() => {
        if (recordedBlob) {
            saveAudio();
        }
    }, [recordedBlob]); // This useEffect will run after recordedBlob updates

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const onData = (recordedData) => {
        // Process stream data here
    };

    const onStop = (recordedData) => {
        setRecordedBlob(recordedData.blob);
    };

    const handleNoteSubmit = (selectedNote) => {
        onNoteSelect(selectedNote);
    };

    const saveAudio = async () => {
        // Create a new Blob object
        const audioBlob = new Blob([recordedBlob], { type: 'audio/wav' });
        // console.log(selectedPatient._id)
        const patientID = selectedPatient._id


        const formData = new FormData();
        formData.append('audioFile', audioBlob, 'recorded_audio.wav');
        formData.append('patientID', patientID);


        // const res = apis.addPatientNote(formData);
        const res = await apis.addPatientNote(formData);
        // console.log(res.data)

        // send back the note data
        handleNoteSubmit(res.data)
    };

    return (
        <Paper elevation={3} style={{ padding: '16px' }}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <h2>New Patient Note</h2>
                </Grid>
                <Grid item xs={12} style={{ overflow: 'hidden' }}>
                    <ReactMic
                        record={isRecording}
                        className="sound-wave"
                        echoCancellation={false}
                        onStop={onStop}
                        onData={onData}
                        strokeColor="#FFFFFF"
                        backgroundColor="#080c2c"
                    />
                </Grid>
                <Grid item xs={12} style={{ overflow: 'hidden' }}>

                    {isRecording ?
                        <Button variant="contained" style={{ backgroundColor: '#FF0000' }} onClick={stopRecording}>
                            Stop Recording
                        </Button> :
                        <Button variant="contained" color="primary" onClick={startRecording}>
                            Start Recording
                        </Button>}
                </Grid>

            </Grid>
        </Paper>
    );
};

export default NewPatientNote;


// import React, { useState, useEffect, useRef } from 'react';
// import apis from '../../../../api';
// import { Paper, Button, Grid } from '@mui/material';

// const NewPatientNote = ({ onNoteSelect, selectedPatient }) => {
//     const [isRecording, setIsRecording] = useState(false);
//     const [recordedBlob, setRecordedBlob] = useState(null);
//     const mediaRecorderRef = useRef(null);
//     const audioChunksRef = useRef([]);

//     useEffect(() => {
//         if (recordedBlob) {
//             saveAudio();
//         }
//     }, [recordedBlob]);

//     const startRecording = async () => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             mediaRecorderRef.current = new MediaRecorder(stream);
//             mediaRecorderRef.current.ondataavailable = (event) => {
//                 audioChunksRef.current.push(event.data);
//             };
//             mediaRecorderRef.current.onstop = () => {
//                 const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
//                 setRecordedBlob(audioBlob);
//                 audioChunksRef.current = []; // Clear the chunks
//             };
//             mediaRecorderRef.current.start();
//             setIsRecording(true);
//         } catch (err) {
//             console.error('Error accessing media devices.', err);
//         }
//     };

//     const stopRecording = () => {
//         mediaRecorderRef.current.stop();
//         setIsRecording(false);
//     };

//     const handleNoteSubmit = (selectedNote) => {
//         onNoteSelect(selectedNote);
//     };

//     const saveAudio = async () => {
//         const patientID = selectedPatient._id;
//         const formData = new FormData();
//         formData.append('audioFile', recordedBlob, 'recorded_audio.wav');
//         formData.append('patientID', patientID);

//         try {
//             const res = await apis.addPatientNote(formData);
//             console.log('API response:', res.data);
//             handleNoteSubmit(res.data);
//         } catch (error) {
//             console.error('Error saving audio:', error);
//         }
//     };

//     return (
//         <Paper elevation={3} style={{ padding: '16px' }}>
//             <Grid container spacing={2}>
//                 <Grid item xs={12}>
//                     <h2>New Patient Note</h2>
//                 </Grid>
//                 <Grid item xs={12} style={{ overflow: 'hidden' }}>
//                     {isRecording ? (
//                         <Button variant="contained" style={{ backgroundColor: '#FF0000' }} onClick={stopRecording}>
//                             Stop Recording
//                         </Button>
//                     ) : (
//                         <Button variant="contained" color="primary" onClick={startRecording}>
//                             Start Recording
//                         </Button>
//                     )}
//                 </Grid>
//             </Grid>
//         </Paper>
//     );
// };

// export default NewPatientNote;

