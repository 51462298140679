import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ConsoleNavBar from '../ConsoleAppbar';
import apis from '../../../api';
import MobilePatientList from './mobilePatientList';
import MobilePatientDashboard from './mobilePatientDashboard';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileUserConsole = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get('patient');
    //on load if there is a patient in the url params we assing
    if (patientId) {
      setSelectedPatient({ _id: patientId });
    }
    //else no patient in params we set to null
    else{
      setSelectedPatient(null)
    }
  }, [location.search]);

  const handlePatientClickMobile = (patient) => {
    setSelectedPatient(patient);
    navigate(`?patient=${patient._id}`);
  };

  // Check if there are no query parameters in the URL
  const showPatientList = location.search === '';

  return (
    //og was grid component
    <>
      {showPatientList && (
        <MobilePatientList
          userID={localStorage.getItem('user')}
          onPatientClick={handlePatientClickMobile}
        />
      )}
      {selectedPatient && (
        <MobilePatientDashboard
          userID={localStorage.getItem('user')}
          selectedPatient={selectedPatient}
        />
      )}
    </>
  );
};

export default MobileUserConsole;
