import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import apis from '../../../api';
import {
  List, ListItem, ListItemText, Typography, Divider, Paper, Grid, TextField, Button,
  Modal, Backdrop, Fade, Checkbox, FormControlLabel, FormGroup
} from '@mui/material';

const CreatePatientModal = ({ open, onClose, onSubmit }) => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState('');
  const [formErrors, setFormErrors] = useState({
    firstname: false,
    lastname: false,
    dob: false,
    gender: false,
  });

  const validateForm = () => {
    const errors = {
      firstname: !firstname,
      lastname: !lastname,
      dob: !dob,
      gender: !gender,
    };

    setFormErrors(errors);

    return Object.values(errors).every((field) => !field);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit({ firstname, lastname, dob, gender });
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-patient-modal-title"
      aria-describedby="create-patient-modal-description"
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper style={{
        padding: '16px',
        backgroundColor: '#ffffff',
        width: '60%', // Adjust as needed
        maxWidth: '400px', // Maximum width
        borderRadius: '8px',
      }}>
        {/* form fields go here */}
        <TextField
          label="First Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={firstname}
          onChange={(e) => setFirstName(e.target.value)}
          required
          error={formErrors.firstname}
          helperText={formErrors.firstname ? 'Field is required' : ''}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={lastname}
          onChange={(e) => setLastName(e.target.value)}
          required
          error={formErrors.lastname}
          helperText={formErrors.lastname ? 'Field is required' : ''}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Birth"
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                error={formErrors.dob}
                helperText={formErrors.dob ? 'Field is required' : ''}
              />
            )}
          />
        </LocalizationProvider>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={gender === 'male'} onChange={() => setGender('male')} />}
            label="Male"
            required
            error={formErrors.gender}
          />
          <FormControlLabel
            control={<Checkbox checked={gender === 'female'} onChange={() => setGender('female')} />}
            label="Female"
            required
            error={formErrors.gender}
          />
          <FormControlLabel
            control={<Checkbox checked={gender === 'other'} onChange={() => setGender('other')} />}
            label="Other"
            required
            error={formErrors.gender}
          />
        </FormGroup>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Paper>
    </Modal>
  );
};

const PatientList = ({ userID, onPatientClick, onCreateNewPatient }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreatePatientModalOpen, setCreatePatientModalOpen] = useState(false);
  const [patients, setPatients] = useState([]);

  useEffect(() => {

    const user = JSON.parse(userID)._id;
  
    // Fetch patient data when the component mounts or userID changes
    const fetchPatients = async () => {
      try {

        const userId = user;
        const authToken = localStorage.getItem('jwtToken')
        const response = await apis.getPatientsByUserID(userId, authToken);
        setPatients(response.data);

      } catch (error) {

        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatients();
    
  }, []);

  const filteredPatients = patients.filter((patient) =>
    `${patient.firstname} ${patient.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateNewPatient = () => {
    setCreatePatientModalOpen(true);
  };

  const handleCloseCreatePatientModal = () => {
    setCreatePatientModalOpen(false);
  };

  const handleCreatePatientSubmit = async (newPatientData) => {
    try {
      const user = JSON.parse(userID)._id;

      // Add the user ID to the newPatientData object
      const patientDataWithUserID = {
        ...newPatientData,
        userID: user,
      };
      const authToken = localStorage.getItem('jwtToken');

      // Handle the submission of the new patient data
      console.log('New Patient Data:', patientDataWithUserID);

      // Send data to the API
      const response = await apis.addPatient(patientDataWithUserID, authToken);

      // Check if the API request was successful
      if (response.status === 200) {
        // Refresh the page
        window.location.reload();
      } else {
        // Handle other status codes or errors as needed
        window.alert('Failed to add patient. Status:', response.status);
        console.error('Failed to add patient. Status:', response.status);
        // You can display an error message to the user or perform other error handling here
      }
    } catch (error) {
      // Handle any unexpected errors
      window.alert('Error submitting new patient data:', error);
      console.error('Error submitting new patient data:', error);
    }
  };

  const [height, setHeight] = React.useState(window.innerHeight);
  return (
    <Grid container xs={4} md={3} style={{ backgroundColor: '#080c2c', color: '#ffffff' }}>
      <Grid item padding={1.5}>
        <Paper elevation={3} style={{ backgroundColor: '#080c2c'}} >
          {/* <Paper elevation={3} style={{  padding: '16px', backgroundColor: '#080c2c', color: '#ffffff' }}> */}

          {/* Create New Patient Button */}
          <Button variant="contained" color="primary" fullWidth onClick={handleCreateNewPatient}>
            Create New Patient
          </Button>
          {/* Search Input */}
          <TextField
            label="Search"
            variant="outlined"
            margin="normal"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              style: {
                color: '#080c2c',   // Text color
                backgroundColor: '#ffffff', // Background color
                border: '1px solid #ffffff', // Border color
                borderRadius: '4px', // Optional: Add border-radius for rounded corners
              },
            }}
          />

          <Paper elevation={3} style={{ height: height, padding: '16px', backgroundColor: '#080c2c', color: '#ffffff', overflowY: 'auto' }}>
          {/* <Paper elevation={3} style={{ height: "100vh", padding: '16px', backgroundColor: '#080c2c', color: '#ffffff', overflowY: 'auto' }}> */}
          {/* Patient List */}
          <List>
            {filteredPatients.map((patient) => (
              <ListItem key={patient.id} onClick={() => onPatientClick(patient)} style={{ backgroundColor: '#363856', color: '#ffffff', marginBottom: '4px', borderRadius: '5px'
            }}>
                <ListItemText primary={`${patient.firstname} ${patient.lastname}`} />
              </ListItem>
            ))}
          </List>
          </Paper>
          {/* Create Patient Modal */}
          <CreatePatientModal
          open={isCreatePatientModalOpen}
          onClose={handleCloseCreatePatientModal}
          onSubmit={handleCreatePatientSubmit}
        />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PatientList;
