import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
// import { Link, BrowserRouter as Router } from 'react-router-dom';  // Import 
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../../api';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const LoginForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Email: '',
    Password: '',
    loginFailed: false,
  });

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
      loginFailed: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { Email, Password } = formData;

    // window.alert(Email);
    // const response = await api.loginUser({ email: Email, password: Password });
    // const response = await api.loginUser({ email: Email, password: Password });

    try {

      const response = await api.loginUser({ email: Email, password: Password });
      
      console.log(response.data.success);
      console.log(response);

      const user = response.data.user;
      const token = response.data.token;

      if (response.data.success){
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('jwtToken', token);
      navigate('/userconsole');
      }
      else{
        window.alert(response.data.message)
      }

      // Handle the success scenario, e.g., display a success message
      // window.alert('Login successful!');
    } catch (error) {
      window.alert(error)
      console.log(error);
      setFormData({
        ...formData,
        loginFailed: true,
      });
    }

    // Clear the form fields
    setFormData({
      Email: '',
      Password: '',
      loginFailed: false,
    });
  };

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <ThemeProvider theme={theme}>
        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
          <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
            <Grid item>
              <Typography variant="h2" mb={2} color="#3b3b3b" sx={{ fontFamily: 'abel' }}>
                Login
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h6" mb={2} color="#3b3b3b" sx={{ fontFamily: 'abel' }}>
                {/* <Link href="./signup" color="inherit"> */}
                <Link href="./signup" color="inherit">
                  Sign Up
                </Link>
              </Typography>
            </Grid>

            {['Email', 'Password'].map((field) => (
              <Grid key={field} item className="form-group">
                <OutlinedInput
                  sx={{
                    fontFamily: 'poppins',
                    fontSize: 20,
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 300,
                    borderColor: formData.loginFailed && field === 'Password' ? 'red' : undefined,
                  }}
                  type={field === 'Password' ? 'password' : 'text'}
                  placeholder={field}
                  required
                  className="form-control"
                  value={formData[field]}
                  onChange={handleChange(field)}
                />
              </Grid>
            ))}

            {formData.loginFailed && (
              <Typography color="red" sx={{ fontFamily: 'abel' }}>
                Incorrect email or password. Please try again.
              </Typography>
            )}

            <Grid item mt={2} className="form-group">
              <Button type="submit" style={{ fontSize: '30px', backgroundColor: '#080c2c' }} sx={{ fontFamily: 'abel' }} variant="contained" size="large">
                Login
              </Button>
            </Grid>
          </form>
        </Paper>
      </ThemeProvider>
    </Grid>
  );
};

export default LoginForm;
