import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import UserConsole from './pages/AuthUserPages/UserConsole';
import PrivateRoute from './PRoute';
import AnonymousRoute from './ARoute';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => {
    // Check if user and JWT are stored in local storage
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwtToken');
    
    if (storedUser && storedToken) {
      setIsAuthenticated(true);
    }

  }, []); // Run this effect only once on component mount

  // This will log the updated value after the component re-renders
useEffect(() => {
  console.log("SET");
  console.log(isAuthenticated);
}, [isAuthenticated]);

  return (
    <Router>

      
      <Routes>
        <Route element={<AnonymousRoute />}>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/signup' element={<SignUp />} />
          <Route exact path='/login' element={<Login />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path='/userconsole' element={<UserConsole />} />
        </Route>

      </Routes>
    </Router>
  );
}

export default App;
