// UserConsole.js
// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PatientList from './UserConsoleComponents/PaitentList';
import PatientInformation from './UserConsoleComponents/PatientConsoleComponents/PatientInformation';
import { useMediaQuery, useTheme } from '@mui/material';
import ConsoleNavBar from './ConsoleAppbar';
import PatientConsole from './UserConsoleComponents/PatientConsole';
import apis from '../../api';
import MobilePatientList from './mobileLayout/mobilePatientList';
import MobileUserConsole from './mobileLayout/mobileUserConsole';
import DesktopUserConsole from './desktopLayout/dekstopUserConsole';

const UserConsole = () => {

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        // Check if both jwtToken and user ID are present in localStorage
        const authToken = localStorage.getItem('jwtToken');
        const user = localStorage.getItem('user');
        const userId = JSON.parse(user)._id

        if (!authToken || !userId) {
          // If either token or userId is missing, do not proceed with the API call
          console.error('Token or user ID is missing.');
          return;
        }

        // Make an API call to check JWT token expiration
        await apis.getPatientsByUserID(userId, authToken);

        // If the call is successful, the token is not expired
        console.log('Token is valid');
      } catch (error) {
        // If there's an error, it could be due to token expiration
        console.error('Token is expired or invalid:', error);

        // Clear local storage and refresh the page
        localStorage.clear();
        window.location.reload();
      }
    };

    checkTokenExpiration();
  }, []);

  const theme2 = useTheme();
  const isSmallScreen = useMediaQuery(theme2.breakpoints.down('sm'));

  return (
    <>

      {!isSmallScreen ? (
        <>
            <ConsoleNavBar />
            <DesktopUserConsole />
        </>
      ) : (
        <div style={{ height: '100vh', backgroundColor: '#080c2c' }}>
          <ConsoleNavBar />
          <MobileUserConsole />
        </div>
      )}
    </>
  );
};

export default UserConsole;

