import React from 'react';
import { Grid, Typography } from '@mui/material';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LoginForm from './components/landingComps/forms/LoginForm';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from '@mui/material';

const Login = () => {
  const theme2 = useTheme();
  const isSmallScreen = useMediaQuery(theme2.breakpoints.down('md'));

  const renderFeatureBox = (icon, title, subtitle, additionalSubtitle) => (
    <Grid padding={5} paddingBottom={1} paddingTop={1} textAlign="center">
      {icon}
      <Typography mb={2} variant="h5" color="#FFFFFF" sx={{ fontFamily: 'Abel' }}>
        {title}
      </Typography>

      {subtitle && (
        <Typography mb={2} variant="h5" color="#FFFFFF" sx={{ fontFamily: 'Abel' }}>
          {subtitle}
        </Typography>
      )}

      {additionalSubtitle && (
        <Typography mb={2} variant="h5" color="#FFFFFF" sx={{ fontFamily: 'Abel' }}>
          {additionalSubtitle}
        </Typography>
      )}
    </Grid>
  );

  return (
    <div>
      {!isSmallScreen ? (
        <Grid container>
          <Grid item xs={12} md={6} style={{ background: 'linear-gradient(to right, #080c2c, #191b2a)', height: '100vh' }}>
            <Typography padding={5} variant="h2" color="#2074d4" sx={{ fontFamily: 'Abel' }}>
              <Link href="./" underline="none" color="inherit">
                Wava
              </Link>
            </Typography>

            {renderFeatureBox(
              <GraphicEqIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'Ambient Listening'
            )}

            {renderFeatureBox(
              <TextSnippetIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'AI Generated SOAP Notes'
            )}

            {renderFeatureBox(
              <LocalHospitalIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'EHR Integration',
              '(coming soon)'
            )}
          </Grid>

          <Grid item xs={12} md={6} style={{ backgroundColor: '#fafbfd', height: '100vh' }}>
            <LoginForm />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} md={6} style={{ backgroundColor: '#fafbfd', height: '100vh' }}>
            <LoginForm />
          </Grid>
          <Grid item xs={12} md={6} style={{ background: 'linear-gradient(to right, #080c2c, #191b2a)', height: '100vh' }}>
            <Typography padding={5} variant="h2" color="#2074d4" sx={{ fontFamily: 'Abel' }}>
              <Link href="./" underline="none" color="inherit">
                Wava
              </Link>
            </Typography>
            {renderFeatureBox(
              <GraphicEqIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'Ambient Listening'
            )}

            {renderFeatureBox(
              <TextSnippetIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'AI Generated SOAP Notes'
            )}

            {renderFeatureBox(
              <LocalHospitalIcon style={{ color: '#FFFFFF' }} fontSize="large" />,
              'EHR Integration',
              '(coming soon)'
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Login;
