import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';


import api from '../../../../api';



let theme = createTheme();
theme = responsiveFontSizes(theme);

class SignupForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      FirstName: '',
      LastName: '',
      Email: '',
      DemoKey: '',
      Password: '',
      ConfirmPassword: '',
      passwordMismatch: false,
    };
  }

  handleChange = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
      passwordMismatch: false,
    });
  };

  //execute once we hit Sign Up
  handleSubmit = async (e) => {
    // const navigate = useNavigate();
    e.preventDefault();

    const { Password, ConfirmPassword, ...signupData } = this.state;

    //check if passwords are matching
    if (Password !== ConfirmPassword) {
      this.setState({ passwordMismatch: true });
      return;
    }

    const firstname = signupData.FirstName;
    const lastname = signupData.LastName;
    const email = signupData.Email;
    const demokey = signupData.DemoKey;
    const password = Password;

    //send out the request to api
    try {
      const response = await api.registerUser({ firstname, lastname, email, demokey, password });
      window.alert(response.data.message);
      // navigate('/login');

    } catch (error) {
      window.alert(error.response.data.error);
    }

    //items that come with 
    this.setState({
      FirstName: '',
      LastName: '',
      Email: '',
      DemoKey: '',
      Password: '',
      ConfirmPassword: '',
      passwordMismatch: false,
    });
  };

  render() {
    return (
        <Grid container alignItems="center" justifyContent="center">
          <ThemeProvider theme={theme}>
            <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
              <form onSubmit={this.handleSubmit} style={{ textAlign: 'center' }}>
                <Grid item>
                  <Typography variant="h2" mb={2} color="#3b3b3b" sx={{ fontFamily: 'abel' }}>
                    Sign Up
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h6" mb={2} color="#3b3b3b" sx={{ fontFamily: 'abel' }}>
                    <Link href="./login" color="inherit">
                      Login
                    </Link>
                  </Typography>
                </Grid>

                {['FirstName', 'LastName', 'DemoKey', 'Email', 'Password', 'ConfirmPassword'].map((field) => (
                  <Grid key={field} item className="form-group">
                    <OutlinedInput
                      sx={{
                        fontFamily: 'poppins',
                        fontSize: 20,
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        minWidth: 300,
                        borderColor: this.state.passwordMismatch && (field === 'Password' || field === 'ConfirmPassword') ? 'red' : undefined,
                      }}
                      type={(field === 'Password' || field === 'ConfirmPassword') ? 'password' : 'text'}
                      placeholder={field.replace(/([a-z])([A-Z])/g, '$1 $2')}
                      required
                      className="form-control"
                      value={this.state[field]}
                      onChange={this.handleChange(field)}
                    />
                  </Grid>
                ))}

                {this.state.passwordMismatch && (
                  <Typography color="red" sx={{ fontFamily: 'abel' }}>
                    Password and Confirm Password must match.
                  </Typography>
                )}

                <Grid item mt={2} className="form-group">
                  <Button type="submit" style={{ fontSize: '30px', backgroundColor: '#080c2c' }} sx={{ fontFamily: 'abel' }} variant="contained" size="large">
                    Sign Up
                  </Button>
                </Grid>
              </form>
            </Paper>
          </ThemeProvider>
        </Grid>
    );
  }
}

export default SignupForm;
