import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';


const buttonTheme = createTheme({
    palette: {
        primary: {
            main: "#161736",
        },
        secondary: {
            main: "#2074d4",
        },
    },
});


export const ConsoleNavBar = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };


    const theme2 = useTheme();
    const isSmallScreen = useMediaQuery(theme2.breakpoints.down('sm'));

    const [height, setHeight] = React.useState(window.innerHeight);


    return (
        <div>
            {!isSmallScreen ? (
                <AppBar position="static" style={{ height:height*.1}} sx={{ top: 0, backgroundColor: 'rgba(8, 12, 44, 0.95)', backdropFilter: 'blur(3px)' }}>
                    <Toolbar>
                        <Box>
                            <Tab style={{ fontSize: '20px' }} label="Wava" />
                        </Box>
                        <Box flexGrow={1} />
                        <Tabs>
                            <Box padding={2} >
                                <Tab style={{ fontSize: '20px' }} label="Dashboard" sx={{ textTransform: "none" }} />
                            </Box>
                            <Box padding={2} >
                                <Tab style={{ fontSize: '20px' }} label="My Account" sx={{ textTransform: "none" }} />
                            </Box>
                        </Tabs>
                        <Typography marginLeft={2} marginRight={1}> | </Typography>
                        <ThemeProvider theme={buttonTheme}>
                            <Box>
                                <Button
                                    onClick={(e) => {
                                        localStorage.setItem('user', null);
                                        localStorage.setItem('jwtToken', null);
                                        window.location.reload();
                                    }}
                                    style={{ fontSize: '20px' }} sx={{ textTransform: "none", color: "white" }}>Logout</Button>
                            </Box>
                        </ThemeProvider>
                    </Toolbar>
                </AppBar>
            ) : (
                <div>
                    <AppBar position="static" sx={{ top: 0, backgroundColor: 'rgba(8, 12, 44, 0.95)', backdropFilter: 'blur(3px)' }}>
                        {/* 
                    backgroundColor: 'rgba(8, 12, 44, 0.95)', // Adjust the alpha value (0.9 in this case) for transparency
                            backdropFilter: 'blur(5px)', // Adjust blur value as needed */}

                        <Toolbar >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                position="static"
                                sx={{ mr: 2 }}
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5" padding={2} component="div" sx={{ flexGrow: 1 }}>
                                Wava
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* Dropdown Menu */}
                    <List
                        component="nav"
                        sx={{
                            position: 'static',
                            top: 64,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(8, 12, 44, 0.95)', // Adjust the alpha value (0.9 in this case) for transparency
                            backdropFilter: 'blur(5px)', // Adjust blur value as needed
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 1000,
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)', // Adjust shadow values as needed

                        }}
                    >
                        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <MenuItem
                                    sx={{
                                        fontFamily: 'Abel',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center', // Center the text horizontally
                                    }}
                                    style={{ fontSize: '20px' }}
                                >
                                    Dashboard
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        fontFamily: 'Abel',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center', // Center the text horizontally
                                    }}
                                    style={{ fontSize: '20px' }}
                                >
                                    My Account
                                </MenuItem>
                                <MenuItem sx={{
                                    fontFamily: 'Abel',
                                    color: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center', // Center the text horizontally
                                }}>
                                    <Typography
                                    >-</Typography>
                                </MenuItem>
                                <MenuItem sx={{
                                    fontFamily: 'Abel',
                                    color: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center', // Center the text horizontally
                                }}>
                                    <Button

                                        onClick={(e) => {
                                            localStorage.setItem('user', null);
                                            localStorage.setItem('jwtToken', null);
                                            window.location.reload();
                                        }}


                                        style={{ fontSize: '20px' }} sx={{ justifyContent: 'center', textTransform: "none", color: "white" }}>Logout</Button>
                                </MenuItem>


                            </List>
                        </Collapse>
                    </List>
                </div>

            )}


        </div>

    );
}
export default ConsoleNavBar;













