import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button, Box } from '@mui/material';
import apis from '../../../../api';

const PatientNote = ({ userID, selectedPatient, noteData }) => {
    const [soap, setSoap] = useState({});
    // const [isLoading, setIsLoading] = useState(true); // Track loading state

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // You can customize the date format as needed
    };

    // useEffect(() => {
    //     const userId = JSON.parse(userID)._id;
    //     const patientId = selectedPatient._id;
    //     const noteId = noteData._id;

    //     const fetchNote = async () => {
    //         try {
    //             const authToken = localStorage.getItem('jwtToken');
    //             const payload = { userId, patientId, noteId };
    //             const response = await apis.getPatientNote(payload, authToken);
    //             setSoap(response.data);
    //             console.log(Object.keys(soap).length)
    //             // setIsLoading(false); // Update loading state when data is fetched
    //         } catch (error) {
    //             console.error('Error fetching patient data:', error);
    //         }
    //     };

    //     fetchNote();
    // }, [noteData]);

    useEffect(() => {
        const userId = JSON.parse(userID)._id;
        const patientId = selectedPatient._id;
        const noteId = noteData._id;
        let shouldFetch = true; // Flag to track whether to fetch or not
    
        const fetchNote = async () => {
            try {
                const authToken = localStorage.getItem('jwtToken');
                const payload = { userId, patientId, noteId };
                const response = await apis.getPatientNote(payload, authToken);
                setSoap(response.data);
                if(Object.keys(response.data).length!=0){
                    shouldFetch = false
                }
                console.log(Object.keys(response.data).length);
            } catch (error) {
                console.error('Error fetching patient data:', error);
            }
        };
    
        const fetchDataInterval = setInterval(() => {
            if (shouldFetch) {
                fetchNote();
            }
        }, 10000); // Fetch data every 10 seconds
    
        // Call fetchNote initially
        fetchNote();
    
        // Clear interval on component unmount or when noteData changes
        return () => {
            clearInterval(fetchDataInterval);
            shouldFetch = false; // Reset the flag on cleanup
        };
    }, [noteData]); // Run effect whenever noteData changes
    

    const handleCopy = (text) => {
        // Function to copy text to clipboard
        navigator.clipboard.writeText(text);
    };

    // Display loading message if soap is empty and still loading
    if (Object.keys(soap).length === 0) {
        return (
            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <div>
                    <Typography variant="h6"><strong>Date of Note:</strong> {formatDate(noteData.date)}</Typography>
                </div>
                <Paper elevation={1} sx={{ p: 2, marginBottom: '8px' }}>
                    <Typography variant="h6">Note processing, this may take a couple of minutes...</Typography>
                </Paper>
            </Paper>
        );
    }

    return (
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <div>
                <Typography variant="h6"><strong>Date of Note:</strong> {formatDate(noteData.date)}</Typography>
            </div>
            {/* Display note data */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Paper elevation={1} sx={{ p: 2, marginBottom: '8px' }}>
                    <Typography variant="body1"><strong>Subjective:</strong> {soap.Subjective}</Typography>
                    <Button onClick={() => handleCopy(soap.Subjective)}>Copy</Button>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, marginBottom: '8px' }}>
                    <Typography variant="body1"><strong>Objective:</strong> {soap.Objective}</Typography>
                    <Button onClick={() => handleCopy(soap.Objective)}>Copy</Button>
                </Paper>
                <Paper elevation={1} sx={{ p: 2, marginBottom: '8px' }}>
                    <Typography variant="body1"><strong>Assessment:</strong> {soap.Assessment}</Typography>
                    <Button onClick={() => handleCopy(soap.Assessment)}>Copy</Button>
                </Paper>
                <Paper elevation={1} sx={{ p: 2 }}>
                    <Typography variant="body1"><strong>Plan:</strong> {soap.Plan}</Typography>
                    <Button onClick={() => handleCopy(soap.Plan)}>Copy</Button>
                </Paper>
            </Box>
        </Paper>
    );
};

export default PatientNote;
