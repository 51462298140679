import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PatientList from '../UserConsoleComponents/PaitentList';
import PatientConsole from '../UserConsoleComponents/PatientConsole';

const DesktopUserConsole = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handlePatientClick = (patient) => {
    console.log(patient)
    setSelectedPatient(patient);
  };

  // const [height, setHeight] = React.useState(window.innerHeight);

  return (
    <Grid container>
        <PatientList
          userID={localStorage.getItem('user')}
          onPatientClick={handlePatientClick}
        />
        <PatientConsole
          userID={localStorage.getItem('user')}
          selectedPatient={selectedPatient}
        />
      {/* </Grid> */}
    </Grid>
  );
};

export default DesktopUserConsole;

