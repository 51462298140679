import React from 'react'
import Appbar from './components/appbar.js';
import Top from './components/landingComps/toplanding.js';
import About from './components/landingComps/aboutus.js';
import Pricing from './components/landingComps/pricing.js';
import Footbar from './components/landingComps/footbar.js';


function Landing() {
  return (
    <>
      <Appbar/>
      <div id="top"></div>
      <Top/>
      <div id="about"></div>
      <About/>
      <div id="pricing"></div>
      <Pricing/>
      <Footbar/>
    </>
  )
}
export default Landing;