import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Doc from './doc.png';
import Docs from './docs.png';
import Scribe from './scribe.png';
import Listen from './listen.png';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MiddleQuadrantComponent from './featurecard';
let theme = createTheme();
theme = responsiveFontSizes(theme);

const About = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid container justifyContent="center" alignItems="center" padding={7} style={{ background: 'linear-gradient(to right, #080c2c, #191b2a)' }}>
          {renderTextContent()}
          {renderAvatar()}

          <Grid item xs={7} paddingTop={15} paddingBottom={5} >
            <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h2">_</Typography>
          </Grid>

          <Grid item xs={7} paddingBottom={10} >
            {/* <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h2">Ambient Listening, AI SOAP Notes, EHR Integration</Typography> */}
            <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h3">Ambient Listening. AI SOAP Notes. EHR Integration.</Typography>

          </Grid>

          {/* <Grid container justifyContent="center" alignItems="center"> */}

            <MiddleQuadrantComponent
              icon={<GraphicEqIcon style={{ color: '#000000' }} fontSize="large" />}
              text="Ambient Listening"
              subtext="Our ambient listening software transcribes patient conversations in real-time, enhancing medical documentation efficiency for better patient care."
              image={Listen}
            />
          <MiddleQuadrantComponent
            icon={<TextSnippetIcon style={{ color: '#000000' }} fontSize="large" />}
            text="AI SOAP Notes"
            subtext=
            'Our software seamlessly converts patient conversations into SOAP notes, streamlining documentation for healthcare professionals and enhancing decision-making.'
            image={Scribe}
          />
          <MiddleQuadrantComponent
            icon={<LocalHospitalIcon style={{ color: '#000000' }} fontSize="large" />}
            text="EHR Integration (Coming Soon)"
            subtext=
            'EHR integration, coming soon, streamlining healthcare documentation for a more efficient and unified experience. Stay tuned!'
            image={Docs}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
};

const renderTextContent = () => (


  <Grid item s={12} md={5} paddingBottom={2} paddingTop={5}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"  // Ensures the Box takes up the full height of the Grid item
      padding={4}    // Add padding for a box-like shape
      borderRadius={10}   // Border radius for rounded corners
      boxSizing="border-box" // Include padding and border in the total width and height
    >
      <Typography mb={2} variant="h3" color="#FFFFFF" sx={{ fontFamily: 'Abel' }}>
        Save time with our AI Scribe
      </Typography>
      <Typography variant="h6" color="#FFFFFF" sx={{ fontFamily: 'Abel' }}>

        Wava's AI transforms your conversations with patients into structured SOAP notes, revolutionizing healthcare documentation. Empower practitioners, streamline processes, and make documentation hassle-free. We're here for you!
      </Typography>
    </Box>
  </Grid>

);

const renderAvatar = () => (
  <Grid item s={12} md={6} textAlign="center">
    <Box display="flex" justifyContent="center">
      <Avatar alt="Doc" src={Doc}
        sx={{
          width: {
            xs: '350px', // Specify the width for extra small screens
            sm: '500px', // Specify the width for small screens
            md: '500px', // Specify the width for medium screens
            // ... and so on
          },
          height: {
            xs: '325px', // Specify the height for extra small screens
            sm: '450px', // Specify the height for small screens
            md: '450px', // Specify the height for medium screens
            // ... and so on
          },
          borderRadius: '3%',
        }}
        style={{ borderRadius: '3%' }} />

      {/* // style={{ width: '500px', height: '450px', borderRadius: '3%' }} /> */}
    </Box>
  </Grid>
);

export default About;
