import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Typewriter from './typewriter.tsx';
import { useMediaQuery, useTheme } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Button from '@mui/material/Button';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function Top() {

    const theme2 = useTheme();
    const isSmallScreen = useMediaQuery(theme2.breakpoints.down('sm'));

    return (
        <>
            <Grid padding={5}  sx={{ bgcolor: '#fafbfd' }}>

                <Grid item paddingTop={25}
                    sx={{
                        height: '100vh',
                        /* Other styling for your grid item goes here */
                    }}
                    textAlign="center"

                >
                    <ThemeProvider theme={theme}>
                        <div>
                            <div>
                                {isSmallScreen ? (
                                    <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h2" color="#3b3b3b">
                                        <Typewriter t={"Wava"} />
                                    </Typography>
                                ) : (
                                    <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h2" color="#3b3b3b">
                                        <Typewriter t={"This is Wava"} />
                                    </Typography>
                                )}
                            </div>

                            <Typography textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h2" color="#3b3b3b">
                                your AI Medical Scribe
                            </Typography>
                        </div>

                        <Typography paddingTop={3} textAlign="center" sx={{ fontFamily: 'Abel' }} variant="h5" color="#2074d4">
                            Let AI generate the SOAP notes for you.
                        </Typography>

                    </ThemeProvider>

                    <Grid item paddingTop={5} textAlign="center" >

                        <Button style={{ fontSize: '20px', backgroundColor: '#080c2c' }} sx={{ fontFamily: 'Abel', textTransform: "none" }} variant="contained" size="large" href='./signup'>Get Started</Button>
                    </Grid>

                </Grid>


            </Grid>
        </>
    )
}

export default Top;
