import React, { useState } from 'react';
import { Paper, Grid, Typography, Divider } from '@mui/material';
// import PatientInformation from './PatientConsoleComponents/PatientInformation';
import PatientInformation from '../UserConsoleComponents/PatientConsoleComponents/PatientInformation';
import PatientNote from '../UserConsoleComponents/PatientConsoleComponents/PatientNote';
import PatientNotes from '../UserConsoleComponents/PatientConsoleComponents/PatientNotes';
import NewPatientNote from '../UserConsoleComponents/PatientConsoleComponents/NewPatientNote';


const MobilePatientDashboard = ({ selectedPatient, userID }) => {
    const [selectedNote, setSelectedNote] = useState(null);
    const [newNote, setNewNote] = useState(null);



    // Function to handle note selection
    // const handleNoteSelection = (noteData) => {
    //     setSelectedNote(noteData);
    // };


      // Function to handle note selection
  const handleNoteSelection = (noteData) => {
    // console.log(noteData)
    setSelectedNote(noteData);
    setNewNote(false);
  };

  // if we get a signal from PatientNotes that we want to handle a new note.
  const handleNewNoteSelection = () => {
    setSelectedNote(null);
    setNewNote(true);
  };

    return (
        <Grid item xs={9}>
            <Paper elevation={3} style={{ height: '100%', padding: '16px' }}>
                <Typography variant="h5">Patient Information</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Grid container
                    style={{ height: '100%', padding: '16px' }}
                    alignContent={"center"} justifyContent={"center"} spacing={2}>
                    {/* patient info */}
                    <Grid item xs={12}>
                        <PatientInformation
                            userID={userID}
                            selectedPatient={selectedPatient} />
                        {/* Pass the handleNoteSelection function to PatientNotes */}
                        {/* <PatientNotes
                            selectedPatient={selectedPatient}
                            onNoteSelect={handleNoteSelection}
                        /> */}
                        <PatientNotes
                            userID={userID}
                            selectedPatient={selectedPatient}
                            onNoteSelect={handleNoteSelection}
                            onNewNoteSelect={handleNewNoteSelection}
                        />
                    </Grid>
                    <Grid item xs={12}>
            {selectedNote ? (
              <PatientNote selectedPatient={selectedPatient} userID={userID} noteData={selectedNote} />
            ) : (
              newNote ? (
                // <></>
                <NewPatientNote
                  onNoteSelect={handleNoteSelection}
                  selectedPatient={selectedPatient}
                />
              ) : (
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Typography>Select a note from PatientNotes or create a new one.</Typography>
                </Paper>
              )
            )}
          </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MobilePatientDashboard;




// here selevt note cell

{/* <Grid item xs={8}>
{selectedNote ? (
  <PatientNote noteData={selectedNote} />
) : (
  <Typography>Select a note from PatientNotes.</Typography>
)}
</Grid> */}