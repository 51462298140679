import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
    palette: {
        primary: {
            main: '#74232a',
        },
        background: {
            default: 'Black',

        },
        text: {
            primary: 'White',

        },
    },
});

function Footbar() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container padding={10} paddingBottom={2} direction="row" sx={{ bgcolor: '#080c2c' }}>
                <Grid xs={12} md={8}>
                    <Typography variant="h3" color="#2074d4" sx={{ fontFamily: 'Abel' }} >
                        Wava
                    </Typography>
                    <Typography variant="h6" >
                        New York, N.Y.
                    </Typography>
                    <Typography mt={5} variant="h6" >
                        Wava 2024 All Rights Reserved
                    </Typography>
                </Grid>
                <Grid item  xs={12} margintop={5} marginBottom={5} md={2} >
                    <Typography sx={{ fontFamily: 'Abel' }} variant="h6" >
                        Links
                    </Typography>

                    <Stack spacing={2}>
                        <Link gutterBottom onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: document.querySelector("#top").offsetTop,
                                behavior: "smooth",
                            });
                        }} style={{ color: '#ffff', }} >
                            Home
                        </Link>
                        <Link gutterBottom onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: document.querySelector("#about").offsetTop,
                                behavior: "smooth",
                            });
                        }} style={{ color: '#ffff', }}>
                            About
                        </Link>
                        <Link gutterBottom onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                                top: document.querySelector("#pricing").offsetTop,
                                behavior: "smooth",
                            });
                        }} style={{ color: '#ffff', }}>
                            Pricing
                        </Link>
                    </Stack>
                </Grid>
          
                <Grid item container xs={12} md={2}>
    
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="flex-end"
                  justify="flex-start"
                >
                  <Typography >
                    Powered By <a href="https://www.linkedin.com/in/kevin-garlepp-a01886180/" style={{ color: '#FFA500', }}>Kevin Garlepp</a>
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>


    );
}

export default Footbar;
