import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const globalStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
`;

const GlobalStyle = styled("style")`
  ${globalStyles}
`;


const StyledBox = styled(Box)`
  &.typewriter-effect {
    display: flex;
    justify-content: center;
    font-family: 'Abel', sans-serif; /* Use Abel font with fallback to sans-serif */
  }

  &.typewriter-effect > .text {
    max-width: 0;
    animation: typing 2s steps(var(--characters)) 1s 1 normal both;
    white-space: nowrap;
    overflow: hidden;
  }

  &.typewriter-effect:after {
    content: "|";
    animation: blink 1s infinite;
    animation-timing-function: step-end;
  }

  @keyframes typing {
    75%,
    100% {
      max-width: calc(var(--characters) * 1ch);
    }
  }

  @keyframes blink {
    0%,
    75%,
    100% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
  }
`;

interface CustomStyles extends React.CSSProperties {
  "--characters": number | string;
}

function Typewriter({t}) {
  const text = t;
  

  const styles: CustomStyles = {
    "--characters": text.length
  };

  return (
    <StyledBox className="typewriter-effect">
      <Box style={styles} className="text" id="typewriter-text">
        {text}
      </Box>
    </StyledBox>
  );
}

export default Typewriter;
