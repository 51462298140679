// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Divider, Button } from '@mui/material';
import PatientInformation from './PatientConsoleComponents/PatientInformation';
import PatientNotes from './PatientConsoleComponents/PatientNotes';
import PatientNote from './PatientConsoleComponents/PatientNote';
import NewPatientNote from './PatientConsoleComponents/NewPatientNote';


const PatientConsole = ({ selectedPatient, userID }) => {

  // handles the selectedNote from either the list of notes OR what is returned from our recording
  const [selectedNote, setSelectedNote] = useState(null);

  // handles the state if we want to set a new note or not
  const [newNote, setNewNote] = useState(null);

  useEffect(() => {
    setSelectedNote(false);
    setNewNote(false)
  },[selectedPatient]);

  // Function to handle note selection
  const handleNoteSelection = (noteData) => {
    // console.log(noteData)
    setSelectedNote(noteData);
    setNewNote(false);
  };

  // if we get a signal from PatientNotes that we want to handle a new note.
  const handleNewNoteSelection = () => {
    setSelectedNote(null);
    setNewNote(true);
  };

  return (
    <Grid item spacing={1} xs={8} md={9}>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Typography variant="h5">Patient Information</Typography>
      </Paper>
      <Divider />
      {selectedPatient ? (
        <Grid container >
          <Grid item xs={4}>
            <PatientInformation
              userID={userID}
              selectedPatient={selectedPatient} />

            {/* Pass the handleNoteSelection function to PatientNotes */}
            <PatientNotes
              userID={userID}
              selectedPatient={selectedPatient}
              onNoteSelect={handleNoteSelection}
              onNewNoteSelect={handleNewNoteSelection}
            />
          </Grid>
          <Grid item xs={8}>
            {selectedNote ? (
              <PatientNote selectedPatient={selectedPatient} userID={userID} noteData={selectedNote} />
            ) : (
              newNote ? (
                <NewPatientNote
                  onNoteSelect={handleNoteSelection}
                  selectedPatient={selectedPatient}
                />
              ) : (
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Typography>Select a note from PatientNotes or create a new one.</Typography>
                </Paper>
              )
            )}
          </Grid>
        </Grid>
      ) : (
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography>Select a patient to view their information.</Typography>
        </Paper>

      )}
    </Grid>
  );
};

export default PatientConsole;
