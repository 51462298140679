import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';


import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';


const buttonTheme = createTheme({
    palette: {
        primary: {
            main: "#161736",
        },
        secondary: {
            main: "#2074d4",
        },
    },
});


export const NavBar = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };


    const theme2 = useTheme();
    const isSmallScreen = useMediaQuery(theme2.breakpoints.down('sm'));

    return (
        <div >
            {!isSmallScreen ? (
                // <AppBar position="sticky" sx={{ backgroundColor: '#2196F3' }}>
                <AppBar sx={{ backgroundColor: 'rgba(8, 12, 44, 0.95)', backdropFilter: 'blur(3px)' }}>
                    <Toolbar>
                        <Box>
                            <Tab onClick={(e) => {
                                e.preventDefault();
                                window.scrollTo({
                                    top: document.querySelector("#top").offsetTop-80,
                                    behavior: "smooth",
                                });
                            }} style={{ fontSize: '15px' }} label="Wava" />
                        </Box>

                        <Box flexGrow={1} />

                        <Tabs>
                            <Box padding={1} >
                                <Tab onClick={(e) => {
                                    e.preventDefault();
                                    window.scrollTo({
                                        top: document.querySelector("#about").offsetTop-60,
                                        behavior: "smooth",
                                    });
                                }} style={{ fontSize: '15px' }} label="About Us" sx={{ textTransform: "none" }} />
                                <Tab onClick={(e) => {
                                    e.preventDefault();
                                    window.scrollTo({
                                        top: document.querySelector("#pricing").offsetTop-60,
                                        behavior: "smooth",
                                    });
                                }} style={{ fontSize: '15px' }} label="Pricing" sx={{ textTransform: "none" }} />
                            </Box>
                        </Tabs>

                        <ThemeProvider theme={buttonTheme}>
                            <Box>
                                <Button href="./signup" style={{ fontSize: '15px' }} sx={{ textTransform: "none" }} color="secondary" variant="contained" size="large">Get Started</Button>
                            </Box>
                        </ThemeProvider>
                        <Typography marginLeft={2} marginRight={1}> | </Typography>
                        <ThemeProvider theme={buttonTheme}>
                            <Box>
                                <Button href="./Login" style={{ fontSize: '15px' }} sx={{ textTransform: "none", color: "white" }}>Login</Button>
                            </Box>
                        </ThemeProvider>

                    </Toolbar>
                </AppBar>
            ) : (
                <div>
                    <AppBar position="sticky" sx={{ position: 'fixed', backgroundColor: 'rgba(8, 12, 44, 0.95)', backdropFilter: 'blur(3px)', zIndex: 1000, }}>
{/* 
                    backgroundColor: 'rgba(8, 12, 44, 0.95)', // Adjust the alpha value (0.9 in this case) for transparency
                            backdropFilter: 'blur(5px)', // Adjust blur value as needed */}

                        <Toolbar >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5" padding={2} component="div" sx={{ flexGrow: 1 }}>
                                Wava
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* Dropdown Menu */}
                    <List
                        component="nav"
                        sx={{
                            position: 'fixed',
                            top: 64,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(8, 12, 44, 0.95)', // Adjust the alpha value (0.9 in this case) for transparency
                            backdropFilter: 'blur(5px)', // Adjust blur value as needed
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 1000,
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)', // Adjust shadow values as needed

                        }}
                    >
                        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <MenuItem
                                    onClick={() => {
                                        console.log('About');
                                        window.scrollTo({
                                            top: document.querySelector("#about").offsetTop-60,
                                            behavior: "smooth",
                                        });
                                        handleMenuClick(); // Close the dropdown after clicking
                                    }}
                                    sx={{
                                        fontFamily: 'Abel',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center', // Center the text horizontally
                                    }}
                                    style={{ fontSize: '20px' }}
                                >
                                    About
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        console.log('Pricing');
                                        window.scrollTo({
                                            top: document.querySelector("#pricing").offsetTop-80,
                                            behavior: "smooth",
                                        });

                                        handleMenuClick(); // Close the dropdown after clicking
                                    }}
                                    sx={{
                                        fontFamily: 'Abel',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center', // Center the text horizontally
                                    }}
                                    style={{ fontSize: '20px' }}
                                >
                                    Pricing
                                </MenuItem>

                                <MenuItem sx={{
                                    fontFamily: 'Abel',
                                    color: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center', // Center the text horizontally
                                }}>
                                    <Button href="./Login" style={{ fontSize: '20px' }} sx={{ justifyContent: 'center', textTransform: "none", color: "white" }}>Login</Button>
                                </MenuItem>
                                <MenuItem sx={{
                                    fontFamily: 'Abel',
                                    color: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center', // Center the text horizontally
                                }}>
                                    <Typography
                                    >-</Typography>
                                </MenuItem>
                                <MenuItem sx={{
                                    fontFamily: 'Abel',
                                    color: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center', // Center the text horizontally
                                }}>
                                    <Button style={{ fontSize: '20px', backgroundColor: '#2074d4' }} sx={{ fontFamily: 'Abel', textTransform: "none" }} variant="contained" size="large" href='./signup'>Get Started</Button>
                                </MenuItem>

                            </List>
                        </Collapse>
                    </List>
                </div>

            )}


        </div>

    );
}
export default NavBar;













