// PatientNotes.js
import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Paper, Grid, Button } from '@mui/material';
import apis from '../../../../api';


const PatientNotes = ({userID, selectedPatient, onNoteSelect, onNewNoteSelect }) => {
    const [notes, setNotes] = useState([]);

    useEffect(() => {

        // window.alert(selectedPatient._id)
        // window.alert(JSON.parse(userID)._id)
        const userId = JSON.parse(userID)._id;
        const patientId = selectedPatient._id
    
        // Fetch patient data when the component mounts or userID changes, or new note is added
        const fetchNotes = async () => {
          try {
            // setNotes({});
            const authToken = localStorage.getItem('jwtToken')
            const payload = {userId,patientId}
            const response = await apis.getPatientNotes(payload, authToken);
            setNotes(response.data);
            // window.alert(JSON.stringify(response.data))
    
          } catch (error) {
            console.error('Error fetching patient data:', error);
          }
        };
    
        fetchNotes();
      }, [userID, selectedPatient,onNoteSelect]);

    const handleNoteClick = (selectedNote) => {
        onNoteSelect(selectedNote);
    };
    const handleNewNoteClick = () => {
        onNewNoteSelect();
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // You can customize the date format as needed
    };

    return (
        <Grid >
            <Paper elevation={3} style={{ height: '100%', padding: '16px' }}>
                {/* Patient List */}
                <Button style={{ fontSize: '20px', backgroundColor: '#080c2c' }} sx={{ fontFamily: 'Abel', textTransform: "none" }} variant="contained" size="medium" onClick={() => handleNewNoteClick()}>New Note</Button>
                <List>
                    {notes.map((note) => (
                        <ListItem
                            key={note.id}
                            button
                            style={{
                                backgroundColor: '#363856', color: '#ffffff', marginBottom: '4px', borderRadius: '5px'
                            }}
                            onClick={() => handleNoteClick(note)}
                        >
                            {/* <ListItemText primary={note.date} /> */}
                            <ListItemText primary={formatDate(note.date)} />

                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Grid>
    );
};

export default PatientNotes;
